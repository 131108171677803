<template>
  <nav class="relative py-6">
    <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
      <div class="relative flex items-center justify-between sm:h-10">
        <div class="flex items-center lg:justify-center px-2 lg:px-0 w-full">
          <div class="flex-shrink-0">
            <router-link to="/" aria-label="Home">
              <img class="h-8 w-auto sm:h-10" src="../../assets/img/logoDark.png" alt="logoBitValencia">
            </router-link>
          </div>
          <div class="hidden lg:block lg:ml-10">
            <div class="flex lg:space-x-10 items-end">
              <router-link to="/" class="font-medium text-gray-500 hover:text-gray-900 ease-in-out navUnderlined">Inicio</router-link>
              <router-link to="/kit-digital" class="font-medium text-gray-500 hover:text-gray-900 ease-in-out navUnderlined">Kit digital</router-link>
              <router-link to="/plataformas" class="font-medium text-gray-500 hover:text-gray-900 ease-in-out navUnderlined">Plataformas ERP, CRM y SCM</router-link>
              <router-link to="/aplicaciones-moviles" class="font-medium text-gray-500 hover:text-gray-900 ease-in-out navUnderlined">Aplicaciones móviles</router-link>
              <router-link to="/blockchain" class="font-medium text-gray-500 hover:text-gray-900 ease-in-out navUnderlined">Blockchain, IOT y Hardware</router-link>
              <a href="/#contacto" class="font-medium text-gray-500 hover:text-gray-900 ease-in-out navUnderlined">Contacto</a>
            </div>
          </div>
        </div>
        <div class="flex lg:hidden">
          <button type="button"
                  @click="sidebarOpen = true"
                  class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" id="main-menu" aria-label="Main menu" aria-haspopup="true">
            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <div class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden z-50"
         :class="sidebarOpen ? 'block' : 'hidden'">
      <div class="rounded-lg shadow-md">
        <div class="rounded-lg bg-white shadow-xs overflow-hidden" role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
          <div class="px-5 pt-4 flex items-center justify-between">
            <a href="/" aria-label="Home">
              <img class="h-8 w-auto" src="../../assets/img/logoDark.png" alt="">
            </a>
            <div class="-mr-2">
              <button type="button"
                      @click="sidebarOpen = false"
                      class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out" aria-label="Close menu">
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div class="px-2 pt-2 pb-3">
            <router-link to="/" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Inicio</router-link>
            <router-link to="/kit-digital" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Kit digital</router-link>
            <router-link to="/plataformas" class="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Plataformas ERP, CRM y SCM</router-link>
            <router-link to="/aplicaciones-moviles" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Aplicaciones móviles</router-link>
            <router-link to="/blockchain" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Blockchain, IOT y Hardware</router-link>
            <a href="/#contacto" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out" role="menuitem">Contacto</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  components: {},
  data() {
    return {
      sidebarOpen: false,
    };
  },
  methods: {},
};
</script>

<style>
#nav a.router-link-exact-active {
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  color: black;
}
</style>
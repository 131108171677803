<template>
  <div>
    <div class="relative overflow-hidden">
      <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full">
        <div class="relative h-full max-w-screen-xl mx-auto">
          <svg class="absolute right-full transform translate-y-1/4 " width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg class="absolute left-full transform translate-y-1/4 " width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
        </div>
      </div>
      <div class="relative pt-8 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
        <main class="mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6">
          <div class="text-center">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              Bienvenido a la
              <br class="xl:hidden">
              <span class="text-indigo-600">Transformación Digital</span>
            </h2>
            <p class="mt-6 max-w-md mx-auto text-xl text-gray-500 sm:text-2xl md:mt-10 md:text-3xl md:max-w-3xl">
              Desarrollamos e implantamos programas informáticos.
            </p>
            <p class="mt-3 max-w-md mx-auto text-xl text-gray-500 sm:text-2xl md:mt-5 md:text-3xl md:max-w-3xl">
              Potencie, simplifique y automatice sus procesos.
            </p>
            <div class="mt-7 max-w-md mx-auto sm:flex sm:justify-center md:mt-10">
              <div class="rounded-md shadow">
                <scrollactive>
                  <a href="#contacto" class="scrollactive-item w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Contacto
                  </a>
                </scrollactive>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <section>
      <div class="py-10 bg-white overflow-hidden">
        <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div class="relative">
            <h2 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Proyectos a su medida
            </h2>
          </div>
          <div class="relative mt-12 sm:mt-16">
            <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div class="lg:col-start-2">
                <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  Plataformas de ERP, CRM y SCM
                </h3>
                <p class="mt-3 text-lg text-gray-500">
                  Planificación empresarial, gestión de clientes y gestión de procesos.
                </p>
                <p class="mt-2 text-lg text-gray-500">
                  Planifique sin esfuerzo, identifique sus puntos fuertes o débiles y decida con seguridad.
                </p>
                <p
                    class="text-lg mt-2"
                > <a
                    href="/plataformas"
                    class="text-indigo-600"
                >Más información
                </a>
                </p>
              </div>

              <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <img class="relative mx-auto" width="800"  src="https://elements-cover-images-0.imgix.net/d85a440e-30bd-4fdd-93b8-370773c038c8?auto=compress%2Cformat&fit=max&w=1170&s=8c45d0568b339d0f9efdad8652868df5"
                     alt="platformsPhoto">
              </div>
            </div>
          </div>
          <div class="relative mt-12 sm:mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div class="relative">
              <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                Aplicaciones Móviles
              </h3>
              <p class="mt-3 text-lg text-gray-500">
                Diseñamos y desarrollamos aplicaciones para solucionar cualquier necesidad.
              </p>
              <p class="mt-2 text-lg text-gray-500">
                ¿Tiene un proyecto? Contacte con nosotros para hacerlo realidad.
              </p>
              <p
                  class="text-lg mt-2"
              > <a
                  href="/aplicaciones-moviles"
                  class="text-indigo-600"
              >Más información
              </a>
              </p>
            </div>
            <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
              <img class="relative mx-auto" width="800"  src="https://elements-cover-images-0.imgix.net/74c917b6-f7c8-429f-abab-eb39d07744f0?auto=compress%2Cformat&fit=max&w=1170&s=dd18c2845d0d14bc880e4886b8b0375a"
                   alt="applicationsPhoto">
            </div>
          </div>
          <div class="relative mt-12 sm:mt-16">
            <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div class="lg:col-start-2">
                <h3 class="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  Blockchain, IOT y Hardware
                </h3>
                <p class="mt-3 text-lg text-gray-500">
                  Sensores, consolas y hardware conectados para controlar procesos industriales.
                </p>
                <p class="mt-2 text-lg text-gray-500">
                  Ordenadores y periféricos para comercios, oficinas y viviendas.
                </p>
                <p
                    class="text-lg mt-2"
                > <a
                    href="/blockchain"
                    class="text-indigo-600"
                >Más información
                </a>
                </p>
              </div>

              <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                <img class="relative mx-auto" width="800"  src="https://elements-cover-images-0.imgix.net/e5652362-616f-408b-a2cb-6135998a4e07?auto=compress%2Cformat&fit=max&w=1170&s=b85b573f3d126f856ce14be62164dad2"
                     alt="blockchainPhoto">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
        <div class="relative overflow-hidden">
          <div class="block absolute inset-y-0 h-full w-full">
            <div class="relative h-full">
              <svg class="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#ad9a0a02-b58e-4a1d-8c36-1b649889af63)" />
              </svg>
              <svg class="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
                <defs>
                  <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="404" height="784" fill="url(#478e97d6-90df-4a89-8d63-30fdbb3c7e57)" />
              </svg>
            </div>
          </div>
          <div class="relative pt-6 pb-12 lg:pb-20">
            <div class="mt-10 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-16 lg:mt-20">
              <div class="text-center">
                <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  Información para tomar
                  <br>
                  <span class="text-indigo-600">decisiones acertadas</span>
                </h2>
                <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  Toda la información de su empresa a un clic. Tome decisiones basadas en datos reales y actualizados al instante.
                </p>
              </div>
            </div>
          </div>
          <div class="relative">
            <div class="absolute inset-0 flex flex-col">
              <div class="flex-1"></div>
              <div class="flex-1 w-full bg-gray-800"></div>
            </div>
            <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
              <img class="relative rounded-lg shadow-lg" src="https://tailwindui.com/img/component-images/top-nav-with-multi-column-layout-screenshot.png" alt="App screenshot">
            </div>
          </div>
        </div>
        <div class="bg-gray-800">
          <div class="max-w-screen-xl mx-auto pt-16 pb-20 px-4 sm:px-6 md:pb-24 lg:px-8">
            <h3 class="text-center text-gray-400 text-2xl font-semibold uppercase tracking-wide">Nuestros clientes</h3>
            <div class="mt-8 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
              <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img class="h-12" src="https://tailwindui.com/img/logos/tuple-logo-gray-400.svg" alt="Tuple">
              </div>
              <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img class="h-12" src="https://tailwindui.com/img/logos/mirage-logo-gray-400.svg" alt="Mirage">
              </div>
              <div class="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <img class="h-12" src="https://tailwindui.com/img/logos/statickit-logo-gray-400.svg" alt="StaticKit">
              </div>
              <div class="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
                <img class="h-12" src="https://tailwindui.com/img/logos/transistor-logo-gray-400.svg" alt="Transistor">
              </div>
              <div class="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
                <img class="h-12" src="https://tailwindui.com/img/logos/workcation-logo-gray-400.svg" alt="Workcation">
              </div>
            </div>
          </div>
        </div>
    </section>
    <section class="relative bg-white" id="contacto">
      <div class="relative bg-white">
        <div class="lg:absolute lg:inset-0">
          <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img class="h-56 w-full object-cover lg:absolute lg:h-full" src="https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80" alt="">
          </div>
        </div>
        <div class="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div class="lg:pr-8">
            <div class="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 class="text-2xl leading-9 font-extrabold tracking-tight sm:text-3xl sm:leading-10">
                Contacto
              </h2>
              <form action="#" method="POST" class="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div class="sm:col-span-1">
                  <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">Nombre</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="first_name" class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  </div>
                </div>
                <div class="sm:col-span-1">
                  <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Correo electrónico</label>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="email" type="email" class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  </div>
                </div>
                <div class="sm:col-span-1">
                  <div class="flex justify-between">
                    <label for="phone" class="block text-sm font-medium leading-5 text-gray-700">Teléfono</label>
                    <span class="text-sm leading-5 text-gray-500">Opcional</span>
                  </div>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input id="phone" class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  </div>
                </div>
                <div class="sm:col-span-2">
                  <div class="flex justify-between">
                    <label for="how_can_we_help" class="block text-sm font-medium leading-5 text-gray-700">
                      ¿Cómo podemos ayudarte?</label>
                    <span class="text-sm leading-5 text-gray-500">Máx. 500 caracteres</span>
                  </div>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <textarea id="how_can_we_help" rows="4" class="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5"></textarea>
                  </div>
                </div>
                <div class="text-right sm:col-span-2">
                  <span class="inline-flex rounded-md shadow-sm">
                    <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                      Enviar
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="bg-white">
        <div class="max-w-7xl mx-auto pt-2 pb-10 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div class="divide-y-2 divide-gray-200">
            <div class="lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Oficina
              </h2>
              <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Valencia
                  </h3>
                  <dl class="mt-2 text-base text-gray-500">
                    <div>
                      <dd>
                        Avenida Cortes Valencianas, 58, planta 5, 46015 Valencia
                      </dd>
                    </div>
                    <div class="mt-1">
                      <dd>
                        Teléfono: 960 45 16 00
                      </dd>
                    </div>
                    <div class="mt-1">
                      <dd>
                        Correo: contact@bitvalencia.com
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      pinned: false,
      sidebarOpen: false,
    };
  },
  methods: {
    addScrollListener() {
      const navHeigth = 1;
      document.addEventListener("scroll", () => {
        const pxFromTop = window.scrollY || window.pageYOffset;
        if (pxFromTop > navHeigth) {
          return (this.pinned = true);
        }

        return (this.pinned = false);
      });
    },
  },
  mounted() {
    this.addScrollListener();
  },
};
</script>
